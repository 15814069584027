@import "onboard-shipyard/dist/shipyard.css";
:root {
  --app-nav-width: 12.5rem;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: #f4f4f4;
}

ob-shore-app {
  display: grid;
  grid-template-rows: [app] 1fr [toast] min-content;
  min-height: 100%;
}

.toast-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  z-index: var(--ob-stack-toast, 20);
}
.toast-wrapper > ob-alert {
  margin: 0 0 1rem;
  width: 100%;
  max-width: 30rem;
}

.app {
  display: grid;
  grid-template: [loader] min-content [header] 50px [stack] 1fr/[sidebar] min-content [stack] 1fr;
  min-height: 100%;
}

.app__loader,
.app__header {
  grid-column: sidebar/span 2;
}

.app__loader {
  --mdc-theme-primary: var(--ob-color-brand);
  background-color: #373737;
}

.app__drawer {
  min-width: var(--app-nav-width);
  border-right: solid thin #dcdcdc;
}

.app__load-mask {
  position: fixed;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}
.app__loader[closed] ~ .app__load-mask {
  display: none;
}

.app__main {
  contain: strict;
  padding: 0;
  overflow: auto;
}

@media (max-width: 540px) {
  .app__drawer,
.app__main {
    grid-area: stack;
  }

  .app__main {
    width: 100vw;
  }
}
.app__header {
  color: #fff;
  background: #373737;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.app__header a {
  color: inherit;
}
.app__header header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem 2px;
  height: 100%;
  width: 100%;
}
@media (min-width: 540px) {
  .app__header .button-menu {
    display: none;
  }
}
.app__header .button-menu ob-icon {
  font-size: 1.5rem;
}
.app__header .logo {
  max-width: 10rem;
  height: 1.5rem;
}
.app__header .logo > img {
  display: block;
  width: 100%;
  height: 1.5rem;
}
.app__header .user {
  margin-left: auto;
  display: flex;
}
.app__header .user .button {
  contain: none;
  color: #fff;
}
.app__header .user .menu {
  min-width: 15rem;
}
@media (max-width: 540px) {
  .app__header .user .button {
    max-width: 1rem;
  }
  .app__header .user .name {
    display: none;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.nav .nav__header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  padding: 0.5rem 1rem;
  height: 3.5rem;
  color: #888;
  font-size: 1rem;
  border-bottom: solid thin #dcdcdc;
  white-space: nowrap;
  overflow: hidden;
}
.nav .nav__header .logo {
  height: 100%;
  width: auto;
}
.nav .nav__header .tenant {
  max-width: var(--app-nav-width);
  overflow: hidden;
  text-overflow: ellipsis;
}
.nav > .nav__group {
  display: contents;
}
.nav > .nav__group > .nav__header {
  height: auto;
  border-top: solid thin #dcdcdc;
  border-bottom: none;
}
.nav > .nav__group > .nav__item:last-child {
  border-bottom: solid thin #dcdcdc;
}
.nav .nav__item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
  color: #000;
}
.nav .nav__item.active {
  color: #03bdd3;
  background: #eee;
}
.nav .nav__item:hover {
  background: #e0e0e0;
}
.nav .nav__item--sub {
  padding-left: 1.5rem;
}
.nav > .nav__version {
  margin-top: auto;
  padding: 0.5rem 1rem;
  color: #aaa;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
}

ob-logbook-page,
ob-schedule-page,
ob-settings-page {
  display: block;
}

.page__filter {
  display: flex;
  align-items: center;
  min-height: 3.5rem;
  padding: 0.5rem 1.5rem;
  background: #fafafa;
  border-bottom: solid thin #dcdcdc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Sticky filter on desktop */
}
@media (min-width: 540px) {
  .page__filter {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.page__main {
  display: block;
  margin: 1rem;
}

/**
 * Auth pages
 * Render in light DOM because of password managers.
 * @see https://fe-solutions.atlassian.net/browse/OO-222
 */
ob-login-page,
ob-password-reset-page,
ob-password-update-page {
  --gap: var(--ob-gap-xl, 2rem);
  display: grid;
  place-content: center;
  height: 100%;
}
ob-login-page .card,
ob-password-reset-page .card,
ob-password-update-page .card {
  --ob-card-gap: var(--gap);
  width: min(90vw, 25rem);
}
ob-login-page .card::part(body),
ob-password-reset-page .card::part(body),
ob-password-update-page .card::part(body) {
  display: grid;
  gap: var(--gap);
}
ob-login-page .logo,
ob-password-reset-page .logo,
ob-password-update-page .logo {
  width: 50%;
  margin: 0 auto;
}
ob-login-page h1,
ob-password-reset-page h1,
ob-password-update-page h1 {
  margin: 0 0 0.5rem;
  font-size: 1.5rem;
}
ob-login-page p,
ob-password-reset-page p,
ob-password-update-page p {
  margin: 0;
}
ob-login-page form,
ob-password-reset-page form,
ob-password-update-page form {
  display: contents;
}
ob-login-page label,
ob-password-reset-page label,
ob-password-update-page label {
  display: block;
  margin: 0 0 0.25rem;
}
ob-login-page input,
ob-password-reset-page input,
ob-password-update-page input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font: inherit;
  border: solid thin #ddd;
  border-radius: 2px;
}
ob-login-page input:not(:placeholder-shown):invalid,
ob-password-reset-page input:not(:placeholder-shown):invalid,
ob-password-update-page input:not(:placeholder-shown):invalid {
  border-color: #c00;
}
ob-login-page .actions,
ob-password-reset-page .actions,
ob-password-update-page .actions {
  display: flex;
  gap: var(--gap);
  align-items: center;
}